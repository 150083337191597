<template>
  <div class="collocation-wrapper">
    <div class="alert">
      <a-alert message="提示"
               :description="description"
               banner />
    </div>
    <div class="assistant">
      <div class="assistant-list">未创建小助手<span class="assistant-num">{{countObj.notcreatedCount}}</span></div>
      <div class="assistant-list">已创建小助手<span class="assistant-num">{{countObj.createdCount}}</span></div>
      <div class="assistant-list">已购买小助手<span class="assistant-num">{{countObj.purchaseCount}}</span></div>
      <div class="assistant-list">已激活小助手<span class="assistant-num">{{countObj.activeCount}}</span></div>
      <div class="assistant-list">离线小助手<span class="assistant-num">{{countObj.offlineCount}}</span></div>
      <div class="assistant-list">在线小助手<span class="assistant-num">{{countObj.onlineCount}}</span></div>
    </div>
    <a-card :bordered='false'>
      <a-form :form="searchForm"
              layout="inline">
            <a-form-item label="关键词">
              <a-input v-model="searchForm.username"
                       placeholder="姓名/账号" />
            </a-form-item>
            <a-form-item label="状态" >
              <a-select v-model="searchForm.robot_status"
                        style="width: 120px">
                <a-select-option value="all">全部</a-select-option>
                <a-select-option value="0">已激活</a-select-option>
                <a-select-option value="2">在线</a-select-option>
                <a-select-option value="1">离线</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="托管时间">
              <a-range-picker @change="handleDateChange" v-model="dateRange"/>
            </a-form-item>
            <a-form-item >
              <a-button type="primary"
                        icon="search"
                        @click="handleSearch">
                搜索
              </a-button>
              </a-form-item>
              <a-form-item >
              <a-button 
                        icon="reload"
                        @click="resetSearchForm">
                重置
              </a-button>
            </a-form-item>

      </a-form>
    </a-card>
    <a-card :bordered='false'>

    <!-- 
    <div class="form-title">
      <div>已选{{selectedRowKeys.length}}</div>
      <div>
        <a-button type="primary" :disabled="selectedRowKeys.length<=0" @click="handleClickInvite">邀请上线</a-button>
      </div>
    </div>
    -->

    <a-table 
             :columns="columns"
             :data-source="tableData"
             :row-key="(record) => record.acctid"
             :loading='loading'
             :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: onSelectChange}"
             :pagination="pagination"
             @change="handleTableChange">
      <template slot="accountNum"
                slot-scope="text,record">
        <div class="account-num-wrapper">
          <div class="left">
            <!-- <img class="img"
                 :src="record.avatar"
                 alt=""> -->
            <a-avatar :src="record.avatar"/>
            <a-tag :color="record.robot_status===2?'green':record.robot_status===1?'gray':'blue'">
             {{record.robot_status===2?'在线':record.robot_status===1?'离线':'已激活'}} 
            </a-tag>
          </div>
          <div class="right">
            <div>姓名：{{record.username}}</div>
            <div>账号：{{record.acctid}}</div>
          </div>
        </div>
      </template>
      <template  slot="code" slot-scope="code">
        <img v-if="code" :src="code" style="height:50px;width:50px;" @click="preImg(code)" />
      </template>
       <a-switch slot="reply" checked-children="开" un-checked-children="关"  slot-scope="text,record" :checked="text" @change="onChangeSwitch($event,record)" />
      <div slot="time" slot-scope="text">
          {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
        </div>
      <template slot="operation"
                slot-scope="text,record">
        <div class="operation-wrapper">
          <a @click="handleOnline(text)" v-if="record.robot_status===1 || record.robot_status === 0">上线</a>
          <!-- <a v-if="record.robot_status===2">下线</a> -->
          <a @click='handleNavigat(text)'>日志</a>
          <a-button type="link" :disabled="text.robot_status===2" @click="handleDel(text)">删除</a-button>
        </div>  
      </template>
    </a-table>
    </a-card>
    <!-- 扫码登录企微弹框 -->
    <a-modal title="扫码登录电脑企业微信"
             v-model="scanVisible"
             :footer="null"
             width="300px">
      <div class="scan-wrapper">
        <img :src="scanCode"
             alt="" class="img">
        <p class="footer-text">使用手机企业微信扫码进行登录</p>
      </div>
    </a-modal>

    <a-modal title="企业微信验证码"
             v-model="verifyVisible"
             :footer="null"
             width="300px">
      <div class="scan-wrapper">
            <a-form layout="inline">
                    <a-form-item
                        label="验证码"
                    >
                        <a-input v-model="verifycode" placeholder="请输入验证码"/>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" @click="verifyConfirm">确定</a-button>
                    </a-form-item>
                </a-form>
        <p class="footer-text">使用输入企业微信验证码</p>
      </div>
    </a-modal>

    

    <!-- 日志 -->
    <a-modal title="日志"
             v-model="logVisible"
             :footer="null"
             width="60%"
             :bodyStyle="{height:'500px',overflow:'scroll'}"
             >
      <div class="log-wrapper">
        <div class="title">
          <div>{{userName}} —— 小助手日志</div>
        </div>
        <a-card>
          <a-table
              :columns="logColumns"
              :data-source="logData"
              :row-key="(record) => record._id"
              :pagination="logPagination"
              @change="handleLogTableChange"
            >
            <div slot="time" slot-scope="text">
              {{text | parseTime('{y}-{m}-{d} {h}:{i}:{s}')}}
            </div>
            <span slot="type" slot-scope="text">{{text===0?'上线':'下线'}}</span>

          </a-table>
        </a-card>
      </div>
    </a-modal>
    <a-modal v-model="isPre" title="预览" width="300px" :footer="null" style="text-align:center;">
          <img :src="codeImg" style="width:200px;height:200px;">
          <a-button type="primary" style="margin-top:20px" @click="loadImg(codeImg)">下载</a-button>
      </a-modal>
  </div>

</template>

<script>
// @ is an alias to /src
import {
    mapState
  } from 'vuex'
  const logColumns = [
        {

          title: '时间',
          key: 'create_time',
          dataIndex: 'create_time',
          scopedSlots: { customRender: 'time' },
        },
        {

          title: '事件',
          key: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
        },
  ]
import {downloadImage} from '@/utils/utils'
export default {
  name: 'Entrust',
  data () {
    return {
      description: '已创建小助手 = 离线小助手 + 在线小助手 + 已激活小助手， 当前创建小助手 = 已购买小助手数量后将不能继续创建了 ！',
      searchForm: {
        username: '',
        robot_status: 'all',
        fromTime: '',
        toTime:''
      },
      dateRange:undefined,
      pagination: {
        total: 0,
        pageSize: 10,//每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["5","10", "20"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      logPagination:{
        total: 0,
        pageSize: 10,//每页中显示10条数据
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20"],//每页中显示的数据
        showTotal: total => `共有 ${total} 条数据`,  //分页中显示总的数据
      },
      tableData:[],
      logColumns,
      columns:[
        {
          title: '序号',
          width: 80,
          className: "text-center",
          customRender: (value, row, index) => `${(this.pagination.current - 1) * this.pagination.pageSize + index + 1}`,
        },
        {

          title: '所属账号',
          width:250,
          key: 'accountNum',
          dataIndex: 'accountNum',
          scopedSlots: { customRender: 'accountNum' },
        },
        {
          title: '二维码',
          key: 'qrcode',
          align:'center',
          dataIndex: 'qrcode',
          scopedSlots: { customRender: 'code' },
        },
        {
          title: '托管时间',
          key: 'update_time',
          align:'center',
          dataIndex: 'update_time',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '自动回复',
          key: 'auto_reply',
          align:'center',
          dataIndex: 'auto_reply',
          scopedSlots: { customRender: 'reply' },
        },
        {
          title: "操作",
          width: 120,
          className: "text-center",
          className: "text-center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      selectedRowKeys: [],
      userName:"",
      loading: false,
      scanVisible: false,
      verifyVisible: false,
      logVisible: false,
      isPre:false,
      verifycode:'',
      login_qrcode_key:'',
      codeImg:'',
      clientId:'',
      logData:[],
      logId:'',
      loginId:'',
      scanCode:'',
      countObj:{
        onlineCount:0,
        offlineCount:0,
        purchaseCount:0,
        notcreatedCount:0,
        createdCount:0,
        activeCount:0
      }
    }
  },
  computed: {
    ...mapState(['mqttInit','company'])
  },
  watch:{
    mqttInit:{
      handler(val){
        if(val){
          this.getCollocationList()
          this.loginListener()
          this.verifyListener()
          this.getLoginCode()
          this.getTotalData()
        }
      },
      immediate:true
    }
  },
  created(){
    
  },
  mounted(){
  },
  methods: {
    loadImg(){
        downloadImage(this.codeImg,'code.png')
    },
    preImg(img){
        this.codeImg = img
        this.isPre = true
    },
    onSelectChange(selectedRowKeys){
      this.selectedRowKeys = selectedRowKeys
    },
    //小助手统计
    getTotalData(){
      let payload = {
        msgId:"searchRobots",
        data: {
        },
        };

        this.$mqtt.doPublish(
            {
                pubTopic: `scrm/weUser/searchRobots`,
                payload,
            },
            (topic, data) => {
            if(data.code===200){
                this.countObj= data.data;
            }
            
            },
            this
        );
    },
    handleSelectChange (value) {
      this.searchForm.status = value
    },
    handleSearch () {
      this.selectedRowKeys = []
      this.pagination.current = 1
      this.getCollocationList()
      this.getTotalData()
    },
    handleDateChange (date, dateString) {
      // console.log(dateString);
      this.searchForm.fromTime = new Date(dateString[0]).getTime()
      this.searchForm.toTime = new Date(dateString[1]).getTime()
    },
    // table 表格 选中
    onSelectChange (selectedRowKeys) {
      // console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    // 企微上线 
    handleOnline (val) {
      this.loginId = val._id
      let payload = {
        clientId:val._id,
        app:this.company,
        msgId:'robot_com_qr'
      };
      this.loading = true
      this.clientId = val._id
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/robot/login",
          payload,
        },
        (topic, data) => {
          this.loading = false
          this.scanCode = data.img
          this.scanVisible = true
        },
        this
      );
    },
    getLoginCode(){
      this.$mqtt.addListener(
        {
          topic: "login",
          delete: false,
        },
        (topic, data,ful) => {
          this.loading = false
          if(!data.success && data.error==103 && ful==`${this.company}/${this.loginId}/login`){
            this.$confirm({
              title: '提示',
              content: `小助手额度已达上限,请联系管理员`

            });
          }
        },
        this
      );
    },
    loginListener(){
      this.$mqtt.addListener(
        {
          topic: "robot_com_bind",
          delete: false,
        },
        (topic, data) => {
          this.scanVisible = false
          this.verifyVisible = false
          this.$store.commit('syncUpdateAgain',true)
          this.handleSearch()
          if(data.code==200){
            this.$message.success('托管成功')
          }else {
            this.$confirm({
              title: '提示',
              content: `托管邀请失败code:${data.code},请联系管理员`
            });
          }
        },
        this
      );
    },
    verifyListener(){
      this.$mqtt.addListener(
        {
          topic: "login_verify",
          delete: false,
        },
        (topic, data) => {
          this.scanVisible = false
          this.verifyVisible = true
          this.login_qrcode_key = data.login_qrcode_key
        },
        this
      );
    },
    verifyConfirm(){
      let payload = {
        clientId:this.clientId,
        msgId:"verify",
        data: {code:this.verifycode,login_qrcode_key:this.login_qrcode_key
        },
      };
      console.log(payload)

        this.$mqtt.doPublish(
            {
                pubTopic: `scrm/robot/verify`,
                payload,
            },
            (topic, data) => {
            console.log(data)
            },
            this
        );
    },
    // 日志
    handleNavigat (val) {
      this.logPagination.current = 1
      this.logId = val._id
      this.getLogData()
      this.userName = val.username
      this.logVisible = true
    },
    getLogData(){
      let payload = {
        msgId:'logList',
        data:{
          user_id:this.logId,
          pageSize:this.logPagination.pageSize,
          pageNum:this.logPagination.current,
        }
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/messageInfoService/list",
          payload,
        },
        (topic, data) => {

          this.logPagination.total = data.count;
          this.logData = data.data
        },
        this
      );
    },
    // 小助手删除
    handleDel (val) {
      let that = this
      this.$confirm({
        title: '',
        content: '您确定要删除该小助手吗？历史数据将被清除',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          that.deleteRobot(val)
        },

      });
    },
    deleteRobot(item){
      let payload = {msgId:"removeRobot",data:{userid:item.acctid}}
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/weUser/removeRobot",
          payload,
        },
        (topic,data) => {
          this.$message.success('删除成功')
          if(data.code==200){
            this.getCollocationList()
            this.getTotalData() 
          }else{
            this.$message.error('删除失败')
          }
            
        },
        this
      );
    },
    handleLogTableChange(pagination){
      this.logPagination = pagination
      this.getLogData()
    },
    // 监听分页
    handleTableChange (pagination) {
      this.pagination = pagination
      this.getCollocationList();
    },
    //点击邀请
    handleClickInvite(){
      // console.log(this.selectedRowKeys)
      this.loading = true
      this.selectedRowKeys.forEach((item)=>{
        this.sendInvite(item)
      })
      setTimeout(()=>{
        this.handleSearch()
      },5000)
    },
    //发送邀请
    sendInvite(id){
      let payload = {
        clientId:id,
        msgId:'cardmsg',
        data: {
          touser:id
        },
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/thirdAppManage/sendCardMsg",
          payload,
        },
        (topic,data) => {
          // console.log(data)
          this.loading = false
          if(data.code==200){
              this.selectedRowKeys = []
              this.$message.success('发送成功')
          }else{
            this.$message.warning('发送失败')
          }          
        },
        this
      );
    },
    // 清空搜索框内容
    resetSearchForm () {
      this.searchForm = {
        username: '',
        robot_status: 'all',
        fromTime: '',
        toTime:''
      }
      this.dateRange = undefined
    },
    onChangeSwitch(val,row){
      let payload = {
        msgId:'editUser',
        data: {
          user_id:row.user_id,
          auto_reply:val
        },
      };
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/wxapi/editUser",
          payload,
        },
        (topic, data) => {
          if(data.success){
            this.getCollocationList()
          }
          
        },
        this
      );
    },
    getCollocationList(){
      if(!this.mqttInit){
        this.$message.info('连接中...')
        return
      }
      this.loading = true
      let param = {}
      if(this.searchForm.username){
        param.username = this.searchForm.username
      }
      if(this.searchForm.robot_status!=="all"){
        param.robot_status = this.searchForm.robot_status
      }
      if(this.searchForm.fromTime){
        param.fromTime = this.searchForm.fromTime
        param.toTime = this.searchForm.toTime
      }
      let payload = {
        msgId:'userlist',
        data: {
          pageSize:this.pagination.pageSize,
          pageNum:this.pagination.current,
          ...param
        },
      };
  // console.log(payload)
      this.$mqtt.doPublish(
        {
          pubTopic: "scrm/weUser/searchUserByPage",
          payload,
        },
        (topic, data) => {
          // console.log(data)
          if(data.code===200){
            this.loading = false;
            this.tableData = data.data.res;
            this.pagination.total = data.data.count;
          }
          
        },
        this
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.collocation-wrapper {
  background-color: #f2f2f2;
}

.assistant {
  background-color: #fff;
  height: 60px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  margin: 15px 0;
  .assistant-list {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-right: 30px;
    .assistant-num {
      color: red;
      margin-left: 20px;
    }
  }
}

.form-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding: 0px 15px;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.account-num-wrapper {
  display: flex;
  align-items: center;
  // justify-content: center;
  .left {
    width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-right: 10px;
    // .img {
    //   display: block;
    //   width: 40px;
    //   height: 40px;
    //   border-radius: 50%;
    //   margin-bottom: 8px;
    // }
  }
  .right {
    text-align: left;
  }
}

.operation-wrapper {
  a {
    display: block;
    margin-bottom: 5px;
  }
}

.log-wrapper {
  padding: 15px;
  .title {
    font-size: 16px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .log-list-title {
    font-size: 16px;
  }
}

.scan-wrapper {
  text-align: center;
  .img{
    width: 200px;
    height: 200px;

  }
  .footer-text {
    margin-top: 15px;
  }
}
::v-deep .ant-tag{
  margin-right: 0px;
}
::v-deep td.text-center,
::v-deep th.text-center {
  text-align: center !important;
}

::v-deep .ant-table-tbody {
  background-color: #fff;
}

// ::v-deep .ant-table-pagination.ant-pagination {
//   float: none;
//   text-align: center;
//   background-color: #fff;
//   margin: 0;
//   padding: 15px 0;
// }
</style>